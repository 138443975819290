import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import toast from "react-hot-toast";
import GoogleLogin from "react-google-login";
import FacebookLogin from "@greatsumini/react-facebook-login";
import {
  mainLogin,
  initiatePasswordReset,
  validateSocialId,
  processLogin,
} from "@mainaction";
import {
  renderFieldText,
  renderFieldCheckbox,
  validateEmail,
  classNames,
} from "@basecomponent";
import { GOOGLEID, FACEBOOKAPPID, MAIN_URL } from "@constant";
import LocaleStrings from "@language";
import logo from "../../assets/theme/logo.png";
import mainImg from "../../assets/image/main_page_img.png";

function Login(props) {
  const navigate = useNavigate();
  const location = useLocation();
  var { handleSubmit, reset, session } = props;
  var [loading, setLoading] = useState(false);
  var [loadingButtonSection, setLoadingButtonSection] = useState(false);
  var [socialLoginType, setSocialLoginType] = useState("");
  var [forgotPass, setForgotPass] = useState(false);
  var [isFbAds, setisFbAds] = useState(false);
  
  
  useEffect(() => {
    if (location?.search) {
      const queryParams = new URLSearchParams(location.search);
      let action = queryParams.get("action");
      setisFbAds(queryParams.has("fb"));
      if (action === "sociallogin") {
        let socialtype = queryParams.get("socialtype");
        if (socialtype === "twitter") {
          let userid = queryParams.get("userid");
          let email = queryParams.get("email");
          let name = queryParams.get("name");

          responseTwitter({
            socialtype: socialtype,
            userid: userid,
            email: email,
            name: name,
          });
        }
      }
    }
  }, []);

  const handleForgotPassword = () => {
    setForgotPass(true);
  };

  const handleSignup = () => {
    navigate("/auth/signup" + location?.search);
  };

  const handleSignin = () => {
    setForgotPass(false);
  };

  const onSubmitForm = (values) => {
    // console.log("values :- ", values);
    values.username = values.email;
    setLoading(true);
    if (forgotPass) {
      props.initiatePasswordReset(values, (res) => {
        setLoading(false);
        if (res.success) {
          reset();
          toast.success(LocaleStrings.login_error_text_email_send_success);
        } else {
          toast.error(LocaleStrings.invalid_email);
        }
      });
    } else {
      props.mainLogin(session, values, (res) => {
        setLoading(false);
        if (res.success) {
          if (res?.response?.[0]?.username) {
            navigate(
              res?.response?.[0]?.userid === 1 ? "/classes" : "/dashboard" + location?.search
            );
          } else {
            navigate("/auth/signupuser" + location?.search);
          }
        } else {
          toast.error(LocaleStrings.login_error_text_invalid_email_password);
        }
      });
    }
  };

  const responseFacebook = (obj) => {
    // console.log("Facebook :- ", obj);

    if (obj?.userID) {
      let values = {};
      values.email = obj.email;
      values.socialid = obj.userID;
      values.socialtype = "facebook";
      // values.action = "login";
      // console.log("values validate :- ", values);

      setLoadingButtonSection(true);
      setSocialLoginType("facebook");
      props.validateSocialId(session, values, (response) => {
        // console.log("response :- ", response);

        if (response?.success === 1) {
          // Login as alredy exist in system
          var sess = {
            ...session,
            session_token: response?.resource?.[0]?.session_token,
            userid: response?.resource?.[0]?.userid,
            dfid: response?.resource?.[0]?.dfid,
            username: response?.resource?.[0]?.username,
            email: response?.resource?.[0]?.email,
            usertype: response?.resource?.[0]?.usertype,
            mqstudent: response?.resource?.[0]?.mqstudent ?? false, // used for student
          };
          props.processLogin(sess, (loginRes) => {
            setLoadingButtonSection(false);
            setSocialLoginType("");

            if (response?.resource?.[0]?.username) {
              navigate(
                response?.resource?.[0]?.userid === 1
                  ? "/classes"
                  : "/dashboard" + location?.search
              );
            } else {
              navigate("/auth/signupuser" + location?.search);
            }
          });
        } else {
          // Signup new user
          setLoadingButtonSection(false);
          setSocialLoginType("");
          toast.error(
            LocaleStrings.login_error_text_social_account_not_connected
          );
        }
      });
    }
  };

  const handleTwitter = () => {
    var url = `${MAIN_URL}twitter_login/index.php`;

    var formElement = document.createElement("form");
    formElement.setAttribute("method", "post");
    formElement.setAttribute("action", url);
    formElement.setAttribute("target", "_parent");

    var hiddenField = document.createElement("input");
    hiddenField.setAttribute("name", "callfrom");
    hiddenField.setAttribute("value", "login");
    hiddenField.setAttribute("class", "hidden");
    formElement.appendChild(hiddenField);

    document.body.appendChild(formElement);
    formElement.submit();
  };

  const responseTwitter = (obj) => {
    // console.log("twitter :- ", obj);

    if (obj?.userid) {
      let values = {};
      values.email = obj.email;
      values.socialid = obj.userid;
      values.socialtype = "twitter";
      // values.action = "signup";
      // values.usertype = obj.usertype;
      // console.log("values validate :- ", values);

      setLoadingButtonSection(true);
      setSocialLoginType("twitter");
      props.validateSocialId(session, values, (response) => {
        // console.log("response :- ", response);

        if (response?.success === 1) {
          // Login as alredy exist in system
          var sess = {
            ...session,
            session_token: response?.resource?.[0]?.session_token,
            userid: response?.resource?.[0]?.userid,
            dfid: response?.resource?.[0]?.dfid,
            username: response?.resource?.[0]?.username,
            email: response?.resource?.[0]?.email,
            usertype: response?.resource?.[0]?.usertype,
            mqstudent: response?.resource?.[0]?.mqstudent ?? false, // used for student
          };
          props.processLogin(sess, (loginRes) => {
            setLoadingButtonSection(false);
            setSocialLoginType("");

            if (response?.resource?.[0]?.username) {
              navigate(
                response?.resource?.[0]?.userid === 1
                  ? "/classes"
                  : "/dashboard" + location?.search
              );
            } else {
              navigate("/auth/signupuser" + location?.search);
            }
          });
        } else {
          // Signup new user
          setLoadingButtonSection(false);
          setSocialLoginType("");
          toast.error(
            LocaleStrings.login_error_text_social_account_not_connected
          );
        }
      });
    }
  };

  const responseGoogle = (obj) => {
    // console.log("Google :- ", obj);

    if (obj?.profileObj) {
      let values = {};
      values.email = obj.profileObj.email;
      values.socialid = obj.profileObj.googleId;
      values.socialtype = "google";
      // values.action = "login";
      // console.log("values validate :- ", values);

      setLoadingButtonSection(true);
      setSocialLoginType("google");
      props.validateSocialId(session, values, (response) => {
        // console.log("response :- ", response);

        if (response?.success === 1) {
          // Login as alredy exist in system
          var sess = {
            ...session,
            session_token: response?.resource?.[0]?.session_token,
            userid: response?.resource?.[0]?.userid,
            dfid: response?.resource?.[0]?.dfid,
            username: response?.resource?.[0]?.username,
            email: response?.resource?.[0]?.email,
            usertype: response?.resource?.[0]?.usertype,
            mqstudent: response?.resource?.[0]?.mqstudent ?? false, // used for student
          };
          props.processLogin(sess, (loginRes) => {
            setLoadingButtonSection(false);
            setSocialLoginType("");

            if (response?.resource?.[0]?.username) {
              navigate(
                response?.resource?.[0]?.userid === 1
                  ? "/classes"
                  : "/dashboard" + location?.search
              );
            } else {
              navigate("/auth/signupuser" + location?.search);
            }
          });
        } else {
          // Signup new user
          setLoadingButtonSection(false);
          setSocialLoginType("");
          toast.error(
            LocaleStrings.login_error_text_social_account_not_connected
          );
        }
      });
    }
  };

  return (
    <>
      <div className={classNames("h-full sm:grid grid-cols-2 bg-authpage")}>
        <div className="hidden sm:block bg-theme bg-main-image bg-no-repeat bg-cover">
          {/* <div className="hidden sm:flex bg-theme items-center justify-center bg-main-image bg-no-repeat bg-cover">
          <div>
            <div className="custom-item-center">
              <img className="h-full w-auto" src={logo} alt="Workflow" />
            </div>
          </div> */}
        </div>

        <div className="flex flex-col items-center justify-center sm:py-11 bg-authpage">
          {forgotPass ? (
            <div className="w-full sm:w-3/4 h-screen sm:h-auto bg-white shadow-2xl rounded-xl mx-auto py-10 px-11 2xs:px-16">
              <div className="flex sm:hidden justify-center mb-5">
                <img
                  className="mb-2 2md:mb-0 h-16 w-auto"
                  src={logo}
                  alt="Workflow"
                />
              </div>
              <h2 className="text-3xl sm:text-4xl font-semibold text-primary">
                {LocaleStrings.login_forgot_pass_title_login}
              </h2>

              <div className="mt-8">
                <form
                  onSubmit={handleSubmit(onSubmitForm)}
                  className="space-y-6"
                >
                  <Field
                    name="email"
                    label={LocaleStrings.login_form_label_email}
                    placeholder={LocaleStrings.login_form_ph_email}
                    type="email"
                    component={renderFieldText}
                    mandatory="true"
                    maxlength={100}
                  />

                  <div>
                    <button
                      className="w-full theme-button-lg"
                      disabled={loading}
                    >
                      <svg
                        className={classNames(
                          loading ? "" : "hidden",
                          "cust-btn-spinner"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="btnloading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.button_send}
                    </button>
                  </div>
                </form>
              </div>

              <div className="flex justify-center mt-8">
                <div className="bottom-5 text-center text-lg 2xs:text-xl">
                  <span className="px-2 text-secondary">
                    {LocaleStrings.login_text_go_to_login}
                  </span>
                  <span
                    onClick={handleSignin}
                    className="text-theme cursor-pointer hover:underline"
                  >
                    {LocaleStrings.button_login}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full sm:w-3/4 bg-white shadow-2xl rounded-xl mx-auto py-10 px-11 2xs:px-16">
              <div className="flex sm:hidden justify-center mb-5">
                <img
                  className="mb-2 2md:mb-0 h-16 w-auto"
                  src={logo}
                  alt="Workflow"
                />
              </div>
              <p className="text-xl font-normal text-secondary mb-1">
                {LocaleStrings.welcome_back}
              </p>
              <h2 className="text-3xl sm:text-4xl font-semibold text-primary">
                {LocaleStrings.login_title_login}
              </h2>

              <div className="mt-8">
                <form
                  onSubmit={handleSubmit(onSubmitForm)}
                  className="space-y-3"
                >
                  <Field
                    name="email"
                    label={LocaleStrings.login_form_label_email}
                    placeholder={LocaleStrings.login_form_ph_email}
                    type="email"
                    component={renderFieldText}
                    mandatory="true"
                    maxlength={100}
                  />
                  <Field
                    name="password"
                    label={LocaleStrings.login_form_label_password}
                    placeholder={LocaleStrings.login_form_ph_password}
                    type="password"
                    component={renderFieldText}
                    mandatory="true"
                    maxlength={100}
                  />

                  <div className="flex items-center justify-end py-2">
                    {/* <div className="flex items-center">
                        <Field
                          name="remember_me"
                          type="checkbox"
                          component={renderFieldCheckbox}
                          label={LocaleStrings.login_form_label_rememberme}
                        />
                      </div> */}

                    <div className="text-lg">
                      <span
                        onClick={handleForgotPassword}
                        className="bg-white text-theme cursor-pointer hover:underline"
                      >
                        {LocaleStrings.login_text_forgot_password}?
                      </span>
                    </div>
                  </div>

                  <div>
                    <button
                      className="w-full theme-button-lg"
                      disabled={loading || loadingButtonSection}
                    >
                      <svg
                        className={classNames(
                          loading ? "" : "hidden",
                          "cust-btn-spinner"
                        )}
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="btnloading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                      {LocaleStrings.button_login}
                    </button>
                  </div>

                  <div>
                    <div className="mt-4 relative">
                      <div
                        className="absolute inset-0 flex items-center"
                        aria-hidden="true"
                      >
                        <div className="w-full border-t border-gray-300" />
                      </div>
                      <div className="relative flex justify-center text-lg">
                        <span className="px-2 bg-white text-secondary">
                          {LocaleStrings.login_common_text_or_login_with}
                        </span>
                      </div>
                    </div>

                    <div className="mt-4">
                      <div className="grid grid-cols-3 gap-3">
                        <div>
                          <FacebookLogin
                            appId={FACEBOOKAPPID}
                            // autoLoad={false}
                            fields="name,email,picture"
                            callback={responseFacebook}
                            render={(renderProps) => (
                              <button
                                type="button"
                                className="w-full inline-flex justify-center py-2 px-4 rounded-md shadow-lg bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                onClick={
                                  loadingButtonSection
                                    ? null
                                    : renderProps.onClick
                                }
                                disabled={loadingButtonSection}
                              >
                                <span className="sr-only">
                                  {LocaleStrings.facebook_signin}
                                </span>

                                {/* loading icon */}
                                <svg
                                  className={classNames(
                                    loadingButtonSection &&
                                      socialLoginType === "facebook"
                                      ? ""
                                      : "hidden",
                                    "cust-btn-spinner"
                                  )}
                                  viewBox="0 0 1024 1024"
                                  focusable="false"
                                  data-icon="btnloading"
                                  width="1em"
                                  height="1em"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                </svg>
                                {/* loading icon */}

                                <svg
                                  className="w-9 h-9"
                                  viewBox="0 0 37 37"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <ellipse
                                    cx="18.6995"
                                    cy="18.577"
                                    rx="15.6824"
                                    ry="15.6918"
                                    fill="url(#paint0_linear_70_900)"
                                  />
                                  <path
                                    d="M24.5398 23.376L25.2364 18.947H20.8785V16.0742C20.8785 14.8622 21.4862 13.6801 23.438 13.6801H25.4206V9.90951C25.4206 9.90951 23.6222 9.61026 21.9035 9.61026C18.3129 9.61026 15.9682 11.732 15.9682 15.5714V18.947H11.9785V23.376H15.9682V34.0833C16.7692 34.2061 17.5886 34.2689 18.4234 34.2689C19.2581 34.2689 20.0775 34.2061 20.8785 34.0833V23.376H24.5398Z"
                                    fill="white"
                                  />
                                  <defs>
                                    <linearGradient
                                      id="paint0_linear_70_900"
                                      x1="18.6995"
                                      y1="2.88519"
                                      x2="18.6995"
                                      y2="34.1758"
                                      gradientUnits="userSpaceOnUse"
                                    >
                                      <stop stopColor="#18ACFE" />
                                      <stop offset="1" stopColor="#0163E0" />
                                    </linearGradient>
                                  </defs>
                                </svg>
                              </button>
                            )}
                            textButton={LocaleStrings.facebook_signin}
                          />
                        </div>

                        <div>
                          <button
                            type="button"
                            className="w-full inline-flex justify-center py-2 px-4 rounded-md shadow-lg bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                            disabled={loadingButtonSection}
                            onClick={handleTwitter}
                          >
                            <span className="sr-only">
                              {LocaleStrings.twitter_signin}
                            </span>

                            {/* loading icon */}
                            <svg
                              className={classNames(
                                loadingButtonSection &&
                                  socialLoginType === "twitter"
                                  ? ""
                                  : "hidden",
                                "cust-btn-spinner"
                              )}
                              viewBox="0 0 1024 1024"
                              focusable="false"
                              data-icon="btnloading"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                            </svg>
                            {/* loading icon */}

                            <svg
                              className="w-9 h-9"
                              viewBox="0 0 37 37"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.986 28.6435C8.75101 28.6435 5.73544 27.7026 3.19727 26.0791C5.35226 26.2186 9.15534 25.8846 11.5208 23.6283C7.96234 23.465 6.35753 20.7358 6.1482 19.5695C6.45056 19.6861 7.89257 19.826 8.7066 19.4995C4.61316 18.4731 3.98519 14.8808 4.12474 13.7845C4.89226 14.321 6.19472 14.5076 6.7064 14.4609C2.89206 11.7317 4.26429 7.62625 4.93878 6.73984C7.67609 10.5322 11.7785 12.6621 16.8536 12.7805C16.758 12.3609 16.7074 11.9239 16.7074 11.4751C16.7074 8.25441 19.3107 5.64349 22.522 5.64349C24.1998 5.64349 25.7117 6.35625 26.773 7.49633C27.8942 7.2336 29.5816 6.61856 30.4065 6.0867C29.9907 7.5796 28.6962 8.82498 27.9132 9.28657C27.9197 9.30227 27.9068 9.2708 27.9132 9.28657C28.601 9.18253 30.4621 8.82486 31.1973 8.32605C30.8337 9.16474 29.4613 10.5592 28.335 11.3399C28.5445 20.5816 21.4737 28.6435 11.986 28.6435Z"
                                fill="#47ACDF"
                              />
                            </svg>
                          </button>
                        </div>

                        <div>
                          <GoogleLogin
                            clientId={GOOGLEID}
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={"single_host_origin"}
                            render={(renderProps) => (
                              <button
                                type="button"
                                className="w-full inline-flex justify-center py-2 px-4 rounded-md shadow-lg bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                onClick={
                                  loadingButtonSection
                                    ? null
                                    : renderProps.onClick
                                }
                                disabled={loadingButtonSection}
                              >
                                <span className="sr-only">
                                  {LocaleStrings.google_signin}
                                </span>

                                {/* loading icon */}
                                <svg
                                  className={classNames(
                                    loadingButtonSection &&
                                      socialLoginType === "google"
                                      ? ""
                                      : "hidden",
                                    "cust-btn-spinner"
                                  )}
                                  viewBox="0 0 1024 1024"
                                  focusable="false"
                                  data-icon="btnloading"
                                  width="1em"
                                  height="1em"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                </svg>
                                {/* loading icon */}

                                <svg
                                  className="w-9 h-9"
                                  viewBox="0 0 37 37"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M34.1045 18.9255C34.1045 17.6353 33.9978 16.6938 33.7667 15.7175H18.7422V21.5408H27.5612C27.3835 22.988 26.4234 25.1674 24.2897 26.6319L24.2597 26.8269L29.0102 30.4356L29.3393 30.4678C32.362 27.7304 34.1045 23.7028 34.1045 18.9255Z"
                                    fill="#4285F4"
                                  />
                                  <path
                                    d="M18.7413 34.2689C23.0619 34.2689 26.6891 32.874 29.3384 30.4679L24.2888 26.632C22.9375 27.5561 21.1238 28.2012 18.7413 28.2012C14.5096 28.2012 10.918 25.4639 9.63766 21.6804L9.45 21.696L4.5104 25.4447L4.4458 25.6208C7.07728 30.7468 12.4825 34.2689 18.7413 34.2689Z"
                                    fill="#34A853"
                                  />
                                  <path
                                    d="M9.6385 21.6805C9.30068 20.7041 9.10517 19.6579 9.10517 18.577C9.10517 17.4959 9.30068 16.4498 9.62073 15.4735L9.61178 15.2655L4.61027 11.4567L4.44663 11.533C3.36207 13.6602 2.73975 16.0489 2.73975 18.577C2.73975 21.1051 3.36207 23.4937 4.44663 25.6208L9.6385 21.6805Z"
                                    fill="#FBBC05"
                                  />
                                  <path
                                    d="M18.7414 8.95266C21.7463 8.95266 23.7732 10.2254 24.929 11.2891L29.4452 6.96507C26.6715 4.43695 23.062 2.88519 18.7414 2.88519C12.4826 2.88519 7.07729 6.40711 4.4458 11.5331L9.61992 15.4735C10.918 11.6901 14.5097 8.95266 18.7414 8.95266Z"
                                    fill="#EB4335"
                                  />
                                </svg>
                              </button>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div className="flex justify-center mt-8">
                <div className="text-center text-lg 2xs:text-xl">
                  <span className="px-2 text-secondary">
                    {LocaleStrings.login_text_dont_have_account}
                  </span>
                  <span
                    onClick={handleSignup}
                    className="text-theme cursor-pointer hover:underline"
                  >
                    {LocaleStrings.button_signup}
                  </span>
                </div>
              </div>
            </div>
          )}

          <div className="hidden sm:flex justify-center sm:pt-10">
            <img
              className="mb-2 sm:mb-0 h-16 w-auto"
              src={logo}
              alt="Workflow"
            />
          </div>
        </div>
      </div>
    </>
  );
}

const validate = (values) => {
  var errors = {};
  var email = values["email"];
  var password = values["password"];

  if (!email || email.trim() === "") {
    errors["email"] = LocaleStrings.required;
  } else if (email && !validateEmail(email)) {
    errors["email"] = LocaleStrings.invalid_email;
  }
  if (!password || password === "") {
    errors["password"] = LocaleStrings.required;
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { mainLogin, initiatePasswordReset, validateSocialId, processLogin },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "SigninForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(Login)
);
