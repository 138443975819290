import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { classNames } from "@basecomponent";
import LocaleStrings from "@language";
import logo from "../../assets/theme/logo.png";
import { usePopup } from '../../popup-fragment/popupcontext';
const HeaderMenu = (props) => {
  var { sidebarOpen, routes, session } = props;
  const location = useLocation();
  const [update, setUpdate] = React.useState(false);
  const { showPopup } = usePopup();
  const close = () => {
    props.close();
  };

  const createLinks = (str) => {
    var routesNew = _.filter(routes, function (list) {
      return (
        list.display &&
        list.layout === "/admin" &&
        list.position === str &&
        (list.section === "both" || list.section === session?.usertype)
      );
    }); // custom check is login not display in sidebar

    // checkking mq teacher or student hide side menu
    var hideSideMenu = [];
    if (session?.mqstudent === true) {
      hideSideMenu = ["messages"];
    }
    if (session?.userid === 1) {
      hideSideMenu = [
        "dashboard",
        "students",
        "questionsets",
        "reports",
        "messages",
      ];
    }

    _.forEach(hideSideMenu, (obj) => {
      routesNew = _.filter(routesNew, (o) => {
        return o.key !== obj;
      });
    });
    // -------------------------------- //

    return routesNew.map((item, key) => {
      var selected = _.includes(location.pathname, item.path);
      if (item?.search && location.search !== item?.search) selected = false;
    
      return (
        <>
          {item?.submenu === undefined ? (
            <a
              key={item.name}
              href={`#${item.path}${item?.search ? item.search : ''}`}
              className={classNames(
                selected
                  ? "bg-theme text-white"
                  : "text-primary hover:text-lg hover:text-white hover:bg-indigo-300",
                "text-lg font-normal group flex items-center px-2.5 py-4 rounded-xl space-x-3 mt-2"
              )}
              aria-current={selected ? "page" : undefined}
            >
              <img
                className="flex-shrink-0 h-6 w-6"
                src={selected ? item.selected : item.icon}
              />
              <div>{item.name}</div>
            </a>
          ) : (
            <ul className="inline-block w-full space-y-2 mt-2">
              {Object.keys(item.submenu).map((key) => (
                <li
                  className={classNames(
                    selected
                      ? "bg-theme text-white"
                      : "text-primary hover:text-lg hover:text-white hover:bg-indigo-300",
                    "text-lg font-normal group flex items-center px-2.5 py-4 rounded-xl space-x-3"
                  )}
                  key={key}
                  onClick={() => {
                    showPopup(item.submenu[key].component);
                  }}
                >
                  <img
                    className="flex-shrink-0 h-6 w-6"
                    src={selected ? item.selected : item.icon}
                  />
                  {key}
                </li>
              ))}
            </ul>
          )}
        </>
      );
    });
  };

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40 lg:hidden" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 flex z-40">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-full w-full bg-sidebar">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-9 right-6">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center"
                    onClick={close}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon
                      className="h-6 w-6 text-secondary"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>

              <div className="flex flex-col flex-grow bg-sidebar overflow-y-auto">
                <div className="flex items-center flex-shrink-0 px-6 py-10">
                  <a
                    href={session?.userid === 1 ? "/#/classes" : "/#/dashboard"}
                  >
                    <img
                      className="block h-17 w-auto"
                      src={logo}
                      alt="Workflow"
                    />
                  </a>
                </div>
                <nav
                  className="mt-3 px-7 flex-1 flex flex-col overflow-y-auto"
                  aria-label="Sidebar"
                >
                  <div className="space-y-2">{createLinks("main")}</div>
                  {session?.userid === 1 ? (
                    ""
                  ) : (
                    <>
                      <div className="py-6 text-base font-semibold">
                      Raccourcis
                      </div>
                      <div className="space-y-2">{createLinks("second")}</div>
                    </>
                  )}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 2xs:w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenu);
