// Redaction.js
/* eslint-disable no-restricted-globals */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import React, { useState, useEffect, useRef, createRef } from 'react';
import { add, forEach } from "lodash";
import PdfImage from "../../assets/image/pdf.png";
import Markdown from "../Markdown.js";
import ReactDOMServer from "react-dom/server";
import Spinner from "./loading/spinner.js";
// eslint-disable-next-line import/no-webpack-loader-syntax
import wowo from "worker-loader!./streamWorker.js";
import MessageOptionBar from "./messageOptionBar.js";
import DropBox from "./component/dropbox.js"; // Importer le nouveau composant
import MessageList from "./component/messageList.js";

const worker = new wowo();

function Redaction({ session, userDetails }) {
  const [userInput, setUserInput] = useState('');
  const chatBoxRef = useRef(null);
  const [autoScroll, setAutoScroll] = useState(true);
  const [messages, setMessages] = useState({
    0: { type: 'ia', message: "<h4>Résoudre les exercices de n'importe quel PDF</h4>" }
  });
  const [fileUrl, setFileUrl] = useState([]);
  const userData = userDetails.data;
  const [userLevelInput, setUserLevelInput] = useState(userData?.level ?? '');
  const [userSubjectInput, setUserSubjectInput] = useState('');

  function imageLoaded(e) {
    chatBoxRef.current.scrollTo(0, chatBoxRef.current.scrollHeight - chatBoxRef.current.clientHeight);
  }
  window.imageLoaded = imageLoaded;

    const SimpleFetch = async (url, type = "POST", data = {}) => {
        const response = await fetch(url, {
        method: type,
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        return await response.json();
    };

    const convertLatexDelimiters = (text) => {
      // Fonction pour équilibrer les accolades
      const balanceBraces = (str) => {
          let stack = [];
          let result = "";
  
          for (const char of str) {
              if (char === '{') {
                  stack.push(char);
                  result += char;
              } else if (char === '}') {
                  if (stack.length > 0) {
                      stack.pop();
                      result += char;
                  }
                  // Ignorer les accolades fermantes superflues
              } else {
                  result += char;
              }
          }
  
          // Ajouter des accolades fermantes manquantes
          while (stack.length > 0) {
              stack.pop();
              result += '}';
          }
  
          return result;
      };
  
      // Remplacer les délimiteurs LaTeX
      text = text
          .replace(/\\\[/g, '$$$')  // Remplace tous les \[ par $$
          .replace(/\\\]/g, '$$$') // Remplace tous les \] par $$
          .replace(/\\\(/g, '$$')  // Remplace tous les \( par $$
          .replace(/\\\)/g, '$$'); // Remplace tous les \) par $$
  
      // Équilibrer les accolades après les remplacements
      return balanceBraces(text);
  };


  worker.onmessage = (event) => {
    let { id, text, baseContent, additionnalData } = event.data;
    if (additionnalData !== undefined) {
      additionnalData = JSON.parse(additionnalData);
    }
    setMessagesForStream(text, id, baseContent, additionnalData);
  };

  const setMessagesForStream = (message, id, baseContent, additionnalData) => {
    setMessages((prevMessages) => ({
      ...prevMessages,
      [id]: { type: 'ia', message: message, baseContent: baseContent, additionnalData: additionnalData },
    }));
    if (autoScroll) {
      chatBoxRef.current.scrollTo(0, chatBoxRef.current.scrollHeight - chatBoxRef.current.clientHeight);
    }
  };

  const handleSend = async (e) => {
    const params = {
      "createThread": Object.keys(messages).length == 1 ? true : false,
      "level": userLevelInput,
      "subject": userSubjectInput
    };
    setAutoScroll(true);
    e.preventDefault();
    if (!session.userid) return;

    let html = "";
    forEach(fileUrl, (url) => {
      html += `<img src="${getUrlForExtension(url)}" onload="window.imageLoaded()" />`;
    });

    const id = Object.keys(messages).length;
    setMessages((prevMessages) => ({
      ...prevMessages,
      [id]: { type: 'user', message: userInput + html },
    }));

    setMessages((prevMessages) => ({
      ...prevMessages,
      [id + 1]: { type: 'ia', message: ReactDOMServer.renderToString(<><h3>Réponse en cours de génération <Spinner /></h3></>) },
    }));

    setTimeout(() => {
      chatBoxRef?.current?.scrollTo(0, chatBoxRef.current.scrollHeight - chatBoxRef.current.clientHeight);
    }, 50);

    var resp = await SimpleFetch("https://ai.kojyto.fr/api/v1/docresolve", "POST", { question: userInput, fileUrl:fileUrl, userid: session.userid, ...params });
    setMessages((prevMessages) => ({
      ...prevMessages,
      [id + 1]: { type: 'ia', message: ReactDOMServer.renderToString(<Markdown>{convertLatexDelimiters(resp.data)}</Markdown>)},
    }));

    setFileUrl([]);
    setUserInput("");
  };


  const removeFileUrl = (urlToRemove) => {
    setFileUrl((prevUrls) => prevUrls.filter((url) => url !== urlToRemove));
  };

  const getUrlForExtension = (url) => {
    const extension = url.split('.').pop().toLowerCase();
    return extension === "pdf" ? PdfImage : url;
  };

  const onFileIsUploaded = (url) => {
    setFileUrl((prevUrls) => [...prevUrls, url]);
  };

  return (
    <div className="h-max-[100vh] flex items-center justify-center bg-gray-50 p-4">
      <div className="w-full max-w-[98%] p-6 bg-white rounded-lg shadow-md">
        <div className="flex flex-col items-center">
          <div className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-2 text-center">Kojyto Solve 🎓</div>
        </div>

        <div>
          <MessageList
            chatBoxRef={chatBoxRef}
            messages={messages}
            introductionMessageLength={1}
            dynamicActions={[]}
          />



          <div className="mt-3 w-full m-auto">
                {fileUrl.map((url, index) => (
                    <div key={index} className="w-20 h-20 rounded relative inline-block ml-2">
                        <svg
                            onClick={() => removeFileUrl(url)}
                            className="absolute right-0"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="red"
                            width="24px"
                            height="24px"
                        >
                            <path d="M19.414 4.586a2 2 0 00-2.828 0L12 9.172 7.414 4.586a2 2 0 00-2.828 2.828L9.172 12l-4.586 4.586a2 2 0 002.828 2.828L12 14.828l4.586 4.586a2 2 0 002.828-2.828L14.828 12l4.586-4.586a2 2 0 000-2.828z" />
                        </svg>
                        <img className="w-20 h-20 rounded" src={getUrlForExtension(url)} />
                    </div>
                ))}
            </div>

          <div className="mt-4 flex items-center space-x-4">
            <DropBox
                onFileIsUploaded={onFileIsUploaded}
                fileUrl={fileUrl}
                removeFileUrl={removeFileUrl}
            />

            <button
                onClick={handleSend}
                className="p-4 bg-theme text-white rounded-lg whitespace-nowrap"
            >
            Envoyer
          </button>
        </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
    userDetails: state.userDetails,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({},
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Redaction);